import React from 'react';
import { StaticImage } from 'gatsby-plugin-image'
import "./index.css"

function Index({children}) {

  return (
    <div className='hexContainer'>

      <div className='hexContainer__bgright'>
        <StaticImage
          src="../../images/dllbg_1.png"
          width={400}
          height={400}
          quality={100}
          placeholder="blurred"
          objectFit='cover'
          alt="DigitalLaboratory hex Background Image"
          title="DigitalLaboratory hex Background Image"
        />
      </div>

      <div className='hexWrapper'>
        {children}
      </div>

      <div className='hexContainer__bgleft'>
        <StaticImage
          src="../../images/dllbg_2.png"
          className=''
          width={400}
          height={400}
          quality={100}
          placeholder="blurred"
          objectFit='cover'
          alt="DigitalLaboratory hex Background Image"
          title="DigitalLaboratory hex Background Image"
        />
      </div>


    </div>
  )
}

export default Index