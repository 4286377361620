import React from 'react';
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby';
import { BiChevronRightCircle } from "react-icons/bi";
import "./index.css"



function Index() {

    return (
        <div className='heroBanner'>
            <div className="heroBanner__background-image">
                <StaticImage
                    src="../../images/hero.jpeg"
                    quality={100}
                    placeholder="blurred"
                    alt="DigitalLaboratory Banner Image"
                    title="DigitalLaboratory Banner Image"
                />
            </div>
            <div className="heroBanner__content">
                <div className="heroBanner__content__container">
                    <h1>Digital Laboratory</h1>
                    <p>Helping accelerate your business via cloud technology</p>
                    <Link to="/contact-us/">
                        <span>Contact Us</span>
                        <BiChevronRightCircle className='chev_icon' />
                    </Link>
                </div>

            </div>
        </div>
    )
}

export default Index