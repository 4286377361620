import * as React from "react"
import Meta from '../components/meta';
import Layout from '../components/layout';
import Hero from '../components/hero';
import RoundedButton from '../components/rounded-button'
import logo from '../images/favicon.png';
import { graphql } from "gatsby";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { BiChevronRightCircle } from "react-icons/bi";
import "./index.css"
import BlogList from '../components/blog-list';
import HexSection from "../components/hex-section"
import SocialScetion from "../components/social-section";


const IndexPage = ({ data }) => {

  const intro = data.strapiHome.intro.data.intro;
  const providedServices = data
    .strapiHome
    .provided_services
    .map((e) => {
      const image = getImage(e.image.localFile);
      return (
        {
          body: e.body.data.body,
          image: {
            alt: e.image.alternativeText,
            gatsbyImage: image
          }
        }
      );
    }
    );
  const whoAreWe = {
    body: data.strapiHome.who_we_are.body.data.body,
    image: {
      alt: data.strapiHome.who_we_are.image.alternativeText,
      gatsbyImage: data.strapiHome.who_we_are.image.localFile.childImageSharp.gatsbyImageData
    }
  }
  const aboutUs = {
    body: data.strapiHome.about_us.body.data.body,
    image: {
      alt: data.strapiHome.about_us.image.alternativeText,
      gatsbyImage: data.strapiHome.about_us.image.localFile.childImageSharp.gatsbyImageData
    }
  }

  const blogs = data.allStrapiBlog?.edges.map((e) => {
    const tags = e.node?.tags ?? [];
    const gatsbyImage = getImage(e.node.cover.localFile);
    const alt = e.node.cover.alternativeText;
    const cover = { alt, gatsbyImage };
    return { ...e.node, tags, cover };
  }) ?? [];






  return (
    <Layout>

      <Hero />
      <section className="intro">

        <ReactMarkdown>
          {intro}
        </ReactMarkdown>
      </section>

      <section className="services">
        <div className="services__container">
          {
            providedServices.map((service) =>
              <div className="services__card" key={service.body}>
                <div className="services__card__image__container">
                  <div className="services__card__image">
                    <GatsbyImage
                      alt={service.image.alt}
                      title={service.image.alt}
                      image={service.image.gatsbyImage}
                    />
                  </div>
                </div>
                <ReactMarkdown>
                  {service.body}
                </ReactMarkdown>
              </div>
            )
          }

        </div>
      </section>

      <HexSection>
        <section className="aboutusSection">

          <div className="aboutusSection__who">
            <div>
              <ReactMarkdown>
                {whoAreWe.body}
              </ReactMarkdown>
            </div>

            <GatsbyImage
              className="aboutusSection__aboutus__image"
              alt={aboutUs.image.alt}
              title={aboutUs.image.alt}
              image={aboutUs.image.gatsbyImage} />

          </div>
          <div className="aboutusSection__aboutus">

            <div>
              <ReactMarkdown components={components}>
                {aboutUs.body}
              </ReactMarkdown>
            </div>

            <GatsbyImage
              className="aboutusSection__who__image"
              alt={whoAreWe.image.alt}
              title={whoAreWe.image.alt}
              image={whoAreWe.image.gatsbyImage} />
          </div>
        </section>

        {/* <SocialScetion feedId={"7a703552-d23f-40df-91f1-2ea1ccd8dd88"}/> */}

        <section className="blogSection">
          <BlogList blogs={blogs} includeHeader={true} />
          <div className="blogSection__readmore">
            <RoundedButton href="/blog/" text="Read More" />
          </div>

        </section>
      </HexSection>
      <section className="contactUs">
        <div className="contactUs__container">
          <p>Learn More About Our Services</p>
          <div className="contactUs__container__btn">
            <RoundedButton href="/contact-us/" text="Contact Us" />
          </div>

        </div>

      </section>

    </Layout>
  )
}

export const Head = () => {
  // console.log("blogs: ", blogs);

  let meta = {
    title: "Home",
    description: "Helping accelerate your business via cloud technology",
    url: "https://www.digitallaboratory.net/",
    image: logo
  };

  return (
    <Meta meta={meta} />
  );
};

export const query = graphql`
    {
      strapiHome {
        intro {
          data {
            intro
          }
        }
        provided_services {
          body {
            data {
              body
            }
          }
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData( placeholder: TRACED_SVG)
              }
            }
            alternativeText
          }
        }
        who_we_are {
          body {
            data {
              body
            }
          }
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData( placeholder: TRACED_SVG)
              }
            }
          }
        }
        about_us {
          body {
            data {
              body
            }
          }
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData( placeholder: TRACED_SVG)
              }
            }
          }
        }
      }
      allStrapiBlog(limit: 4, sort: {fields: date, order: DESC}) {
        edges {
          node {
            id
            title
            date(formatString: "MMMM D, YYYY")
            slug
            tags {
              name
              slug
            }
            cover {
              alternativeText
              localFile {                
                childImageSharp {
                  gatsbyImageData(aspectRatio: 1.77, width: 450, quality: 100)
                }
              }
            }
          }
        }
      }
    }
`;

const components = {
  a: ({ ...props }) => {

    const href = props.href;

    if (props.children.includes("Read More")) {
      return <>

        <Link to={href} className="flex items-center group text-dll-link hover:text-dll-hover">
          <span className="italic font-bold">{props.children}</span>
          <BiChevronRightCircle className='chev_icon ml-1 mt-0.5' />
        </Link>

      </>;
    }

    return <Link href={href}>{props.children}</Link>;
  },
};


export default IndexPage
